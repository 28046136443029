import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import { useLoadingStore } from './loading';
import {
  loginAPI,
} from '@/api/login'
import {
  getRoutePermissionAPI,
} from '@/api/user'

export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  token: string;
  expireAt: string;
  office: string;
  avatar: string;
  name: string;
  title: string;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      title:'',
      logged: true,
      count: 999,
    };
  },
  // state: () => ({
  //   account: {} as Account,
  //   permissions: [] as string[],
  //   role: '',
  //   logged: true,
  // }),
  actions: {
    async login(account: string, password: string) {
      return loginAPI({ account, password }).then(async (response) => {
        if (response.code === 0) {
          this.logged = true;
          const { token, expireAt, name, avatar, title } = response.data;
          console.log(token, expireAt, name, avatar)
          this.account.username = name;
          this.account.avatar = avatar;
          this.account.title = title;
          this.count = 11;
          console.log(this.account)
          localStorage.setItem("account", JSON.stringify(this.account));
          http.setAuthorization(response.data.token, new Date(expireAt));
          await useMenuStore().getMenuList();
          return response.data;
        } else {
          return Promise.reject(response);
        }
      });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('stepin-menu');
        localStorage.removeItem('account');
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },
    async profile() {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return http
        .request<Account, Response<Profile>>('/account', 'get')
        .then((response) => {
          if (response.code === 0) {
            const { setAuthorities } = useAuthStore();
            const { account, permissions, role } = response.data;
            this.account = account;
            this.permissions = permissions;
            this.role = role;
            setAuthorities(permissions);
            return response.data;
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setAuthLoading(false));
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
    async setAuth() {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return getRoutePermissionAPI()
        .then((response) => {
          if (response.code === 0) {
            const { setAuthorities } = useAuthStore();
            setAuthorities(response.data);
            return response.data;
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setAuthLoading(false));
    },
  },
  // getters: {
  //   account: (state) => state.account,
  // },
});
