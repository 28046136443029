import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'workplace',
    redirect: '/workplace',
    meta: {
      title: 'Workplace',
      renderMenu: false,
      icon: 'DashboardOutlined',
    },
  },
  {
    path: '/front',
    name: 'Front',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
        },
        component: () => import('@/pages/login'),
      },
    ],
  },
  {
    path: '/workplace',
    // name: 'workplace2',
    props: true,
    meta: {
      title: 'Workplace',
      renderMenu: false,
      icon: 'DashboardOutlined',
    },
    component: () => import('@/pages/workplace'),
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
];

export default routes;
