<template>
  <a-config-provider :getPopupContainer="getPopupContainer">
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <stepin-view system-name="Rich Plus" logo-src="@/assets/image/logo.png" :class="`${contentClass}`" :user="user"
        :navMode="navigation" :useTabs="useTabs" :themeList="themeList" v-model:show-setting="showSetting"
        v-model:theme="theme" @themeSelect="configTheme">
        <template #headerActions v-if="false">
          <HeaderActions @showSetting="showSetting = true" />
        </template>
        <template #pageFooter>
          <PageFooter />
        </template>
        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
  </a-config-provider>
  <login-modal :unless="['/login']" />
</template>

<script lang="ts" setup>
  import {
    reactive,
    ref,
    watch,
  } from 'vue';
  import {
    useRouter
  } from 'vue-router';
  import {
    useAccountStore,
    useMenuStore,
    useSettingStore,
    storeToRefs
  } from '@/store';
  import useHomeStore from '@/store/home';
  import avatar from '@/assets/avatar.png';
  import {
    PageFooter,
    HeaderActions
  } from '@/components/layout';
  import Setting from './components/setting';
  import {
    LoginModal
  } from '@/pages/login';
  import {
    configTheme,
    themeList
  } from '@/theme';
  import {
    ThemeProvider
  } from 'stepin';
  import {
    computed
  } from 'vue';
  import {
    useThemeStore
  } from 'stepin/es/theme-provider';

  const {
    setPrimaryColor
  } = useThemeStore();
  setPrimaryColor({
    DEFAULT: '#d31145'
  });

  const {
    setAuth
  } = useAccountStore();
  const accountStore = useAccountStore();
  // const {count} = storeToRefs(accountStore);
  const homeStore = useHomeStore();
  setAuth();

  // 获取个人信息
  // profile().then((response) => {
  //   const { account } = response;
  //   console.log(account)
  //   var account1 = JSON.parse(localStorage.getItem("account"));
  //   console.log(account1)
  //   user.name = account1.username;
  //   user.avatar = account1.avatar;
  // });

  const showSetting = ref(false);
  const router = useRouter();

  useMenuStore().getMenuList();

  const {
    navigation,
    useTabs,
    theme,
    contentClass
  } = storeToRefs(useSettingStore());
  const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});

  console.log(
    import.meta.env.VITE_API_URL,
    import.meta.env.VITE_NODE_ENV);
  // accountStore.count++;
  // console.log(accountStore.account, accountStore.count, count);
  console.log(homeStore.doubleCount);
  homeStore.increment();
  console.log(homeStore.doubleCount);
  homeStore.incrementNum(12);
  console.log(homeStore.count);
  const user = reactive({
    name: 'admin',
    avatar: avatar,
    menuList: [
      /* { title: '个人中心', key: 'personal', icon: 'UserOutlined', onClick: () => router.push('/profile') },
      { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
      { type: 'divider' }, */
      {
        title: 'Sign Out',
        key: 'logout',
        icon: 'LogoutOutlined',
        onClick: () => accountStore.logout().then(() => router.push('/login')),
      },
    ],
  });
  
  function getPopupContainer() {
    return document.querySelector('.stepin-layout');
  }
  
  function setUser() {
    var account = JSON.parse(localStorage.getItem("account"));
    if (account) {
      user.name = account.username;
      user.avatar = account.avatar;
    }
  }
  setUser();

  watch(accountStore, (newValue, oldValue) => {
    setUser();
  });
</script>

<style lang="less">
  .stepin-view {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }
  }

  .stepin-tabs-view-content-footer {
    padding-top: 0.5rem !important;
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }

  .stepin-img-checkbox {
    @apply transition-transform;

    &:hover {
      @apply scale-105~"-translate-y-[2px]";
    }

    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
</style>
