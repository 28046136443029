import { defineStore } from 'pinia';

const useHomeStore = defineStore('home', {
  state: () => ({
    count: 0,
  }),
  // state() {
  //   return {
  //     count: 0,
  //   };
  // },
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions:{
    increment() {
      this.count++;
    },
    incrementNum(num:number) {
      this.count=num;
    },
  },
})

export default useHomeStore;