import {
  USER_PAGED_USERS,
  USER_ADD_USER,
  USER_UPDATE_USER,
  USER_DELETE_USER,
  USER_ROUTE_PERMISSION,
} from '@/api/api'
import http from './httpInstance';
import { Response } from '@/types';

/**
 * 获取用户列表数据
 */
export async function getPagedUsersAPI(params: any) {
  return http.request<any, Response<any>>(USER_PAGED_USERS, 'get', params);
}

/**
 * 新增用户
 */
export async function addUserAPI(params: any) {
  return http.request<any, Response<any>>(USER_ADD_USER, 'post_json', params);
}

/**
 * 更新用户
 */
export async function updateUserAPI(params: any) {
  return http.request<any, Response<any>>(USER_UPDATE_USER, 'post_json', params);
}

/**
 * 删除用户
 */
export async function deleteUserAPI(params: any) {
  return http.request<any, Response<any>>(USER_DELETE_USER, 'post_json', params);
}

/**
 * 获取用户路由权限
 */
export async function getRoutePermissionAPI() {
  return http.request<any, Response<any>>(USER_ROUTE_PERMISSION, 'get', {});
}