import {
    LOGIN,
} from '@/api/api'
import http from './httpInstance';
import { Response } from '@/types';

/**
 * 登录
 */
export async function loginAPI(params: any) {
    return http.request<any, Response<any>>(LOGIN, 'post_json', params)
}