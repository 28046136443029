const VITE_API_URL = import.meta.env.VITE_API_URL;
const BASE_URL = process.env.NODE_ENV === 'development' ? '' : VITE_API_URL;

/* Common */
export const UPLOAD_FILE_VNG = 'https://hcm03.vstorage.vngcloud.vn/v1/AUTH_9b010a60fe914d159d0cb3a62773d2e4/richs-rplus-container-prod/';
export const LOGIN = `${BASE_URL}/login`;
export const COMMON_UPLOAD_FILE = `${BASE_URL}/common/UploadFile`;
export const COMMON_ROUTES = `${BASE_URL}/common/GetRoutes`;
export const COMMON_ROLE_LIST = `${BASE_URL}/common/GetRoleList`;
export const COMMON_VNG_TOKEN = `${BASE_URL}/common/GetVNGToken`;
export const COMMON_RICH_AUTHOR_LIST = `${BASE_URL}/common/GetRichAuthorList`;
export const COMMON_TAG_LIST = `${BASE_URL}/common/GetTagList`;
export const COMMON_MEMBERSHIP_LIST = `${BASE_URL}/common/GetMembershipList`;
export const COMMON_PRODUCT_LIST = `${BASE_URL}/common/GetAllProductList`;
export const COMMON_AREA_LIST = `${BASE_URL}/common/GetAreaList`;
export const COMMON_EXPERT_LIST = `${BASE_URL}/common/GetALLExperts`;

/* Tag */
export const TAG_PAGED_TAGS = `${BASE_URL}/Tags/GetPagedTags`;
export const TAG_ADD_TAG = `${BASE_URL}/Tags/AddNewTag`;
export const TAG_UPDATE_TAG = `${BASE_URL}/Tags/UpdateTag`;
export const TAG_DELETE_TAG = `${BASE_URL}/Tags/DeleteTag`;

/* User */
export const USER_PAGED_USERS = `${BASE_URL}/User/GetPagedUsers`;
export const USER_ADD_USER = `${BASE_URL}/User/AddNewUser`;
export const USER_UPDATE_USER = `${BASE_URL}/User/UpdateUser`;
export const USER_DELETE_USER = `${BASE_URL}/User/DeleteUser`;
export const USER_ROUTE_PERMISSION = `${BASE_URL}/User/GetUserRoutePermission`;

/* Role */
export const ROLE_PAGED_ROLES = `${BASE_URL}/Role/GetPagedRoles`;
export const ROLE_ADD_ROLE = `${BASE_URL}/Role/AddNewRole`;
export const ROLE_UPDATE_ROLE = `${BASE_URL}/Role/UpdateRole`;
export const ROLE_DELETE_ROLE = `${BASE_URL}/Role/DeleteRole`;
export const ROLE_ROUTES_PERMISSION = `${BASE_URL}/Role/GetRoleRoutesPermission`;
export const ROLE_SAVE_PERMISSION = `${BASE_URL}/Role/SaveRolePermissions`;

/* MediaLib */
export const MEDIA_TAB_COUNT = `${BASE_URL}/MediaLib/GetTabCount`;
export const MEDIA_SUB_FOLDERS = `${BASE_URL}/MediaLib/GetSubFolders`;
export const MEDIA_ADD_FOLDER = `${BASE_URL}/MediaLib/AddNewFolder`;
export const MEDIA_DELETE_FOLDER = `${BASE_URL}/MediaLib/DeleteFolder`;
export const MEDIA_UPDATE_FOLDER = `${BASE_URL}/MediaLib/UpdateFolder`;
export const MEDIA_PAGED_ITEMS = `${BASE_URL}/MediaLib/GetPagedMediaItems`;
export const MEDIA_MOVE = `${BASE_URL}/MediaLib/MoveMedias`;
export const MEDIA_UPLOAD = `${BASE_URL}/MediaLib/UploadMedia`;
export const MEDIA_DELETE = `${BASE_URL}/MediaLib/DeleteMedias`;
export const MEDIA_UPDATE = `${BASE_URL}/MediaLib/UpdateMediaItem`;
export const MEDIA_RECOVER = `${BASE_URL}/MediaLib/RecoverMedias`;

/* Video */
export const VIDEO_TAB_COUNT = `${BASE_URL}/Video/GetVideoTabCount`;
export const VIDEO_PAGED_VIDEOS = `${BASE_URL}/Video/GetPagedVideos`;
export const VIDEO_CATEGORY_LIST = `${BASE_URL}/Video/GetVideoCategoryList`;
export const VIDEO_SERIE_LIST = `${BASE_URL}/Video/GetVideoSerieList`;
export const VIDEO_ADD = `${BASE_URL}/Video/AddNewVideo`;
export const VIDEO_DETAIL = `${BASE_URL}/Video/GetVideoDetail`;
export const VIDEO_UPDATE = `${BASE_URL}/Video/UpdateVideo`;
export const VIDEO_DELETE = `${BASE_URL}/Video/DeleteVideo`;
export const VIDEO_RECOVER = `${BASE_URL}/Video/RecoverVideo`;

/* News */
export const NEWS_TAB_COUNT = `${BASE_URL}/News/GetTabCount`;
export const NEWS_CATEGORY_LIST = `${BASE_URL}/News/GetCategoryList`;
export const NEWS_PAGED_NEWS = `${BASE_URL}/News/GetPagedNews`;
export const NEWS_SAVE = `${BASE_URL}/News/SaveNews`;
export const NEWS_DETAIL = `${BASE_URL}/News/GetNewsDetail`;
export const NEWS_UPDATE = `${BASE_URL}/News/UpdateNews`;
export const NEWS_DELETE = `${BASE_URL}/News/DeleteNews`;
export const NEWS_ADD_CATEGORY = `${BASE_URL}/News/AddCategory`;
export const NEWS_UPDATE_CATEGORY = `${BASE_URL}/News/UpdateCategory`;
export const NEWS_DELETE_CATEGORY = `${BASE_URL}/News/DeleteCategory`;
export const NEWS_RECOVER = `${BASE_URL}/News/RecoverNews`;

/* Design */
export const DESIGN_FILTER_LIST = `${BASE_URL}/Design/GetDesignFilters`;
export const DESIGN_CATEGORY_LIST = `${BASE_URL}/Design/GetAllDesignCategories`;
export const DESIGN_TAB_COUNT = `${BASE_URL}/Design/GetTabCount`;
export const DESIGN_PAGED_DESIGNS = `${BASE_URL}/Design/GetPagedDesigns`;
export const DESIGN_ADD = `${BASE_URL}/Design/AddNewDesign`;
export const DESIGN_DETAIL = `${BASE_URL}/Design/GetDesignDetail`;
export const DESIGN_UPDATE = `${BASE_URL}/Design/UpdateDesign`;
export const DESIGN_DELETE = `${BASE_URL}/Design/DeleteDesign`;
export const DESIGN_ADD_CATEGORY = `${BASE_URL}/Design/AddCategory`;
export const DESIGN_UPDATE_CATEGORY = `${BASE_URL}/Design/UpdateCategory`;
export const DESIGN_DELETE_CATEGORY = `${BASE_URL}/Design/DeleteCategory`;
export const DESIGN_RECOVER = `${BASE_URL}/Design/RecoverDesign`;

/* Recipe */
export const RECIPE_FILTER_LIST = `${BASE_URL}/Recipe/GetFilters`;
export const RECIPE_CATEGORY_LIST = `${BASE_URL}/Recipe/GetCategories`;
export const RECIPE_DIFFICULT_LIST = `${BASE_URL}/Recipe/GetDifficultList`;
export const RECIPE_TAB_COUNT = `${BASE_URL}/Recipe/GetTabCount`;
export const RECIPE_PAGED_RECIPES = `${BASE_URL}/Recipe/GetPagedRecipes`;
export const RECIPE_ADD = `${BASE_URL}/Recipe/AddNewRecipe`;
export const RECIPE_DETAIL = `${BASE_URL}/Recipe/GetRecipeDetail`;
export const RECIPE_UPDATE = `${BASE_URL}/Recipe/UpdateRecipe`;
export const RECIPE_DELETE = `${BASE_URL}/Recipe/DeleteRecipe`;
export const RECIPE_RECOVER = `${BASE_URL}/Recipe/RecoverRecipe`;

/* Expert */
export const EXPERT_FILTER_LIST = `${BASE_URL}/Expert/GetFilters`;
export const EXPERT_CATEGORY_LIST = `${BASE_URL}/Expert/GetCategories`;
export const EXPERT_TAB_COUNT = `${BASE_URL}/Expert/GetTabCount`;
export const EXPERT_PAGED_EXPERTS = `${BASE_URL}/Expert/GetPagedExperts`;
export const EXPERT_ADD = `${BASE_URL}/Expert/AddNewExpert`;
export const EXPERT_DETAIL = `${BASE_URL}/Expert/GetExpertDetail`;
export const EXPERT_UPDATE = `${BASE_URL}/Expert/UpdateExpert`;
export const EXPERT_DELETE = `${BASE_URL}/Expert/DeleteExpert`;
export const EXPERT_RECOVER = `${BASE_URL}/Expert/RecoverExpert`;

/* Skill */
export const SKILL_FILTER_LIST = `${BASE_URL}/Skill/GetFilters`;
export const SKILL_CATEGORY_LIST = `${BASE_URL}/Skill/GetCategories`;
export const SKILL_TAB_COUNT = `${BASE_URL}/Skill/GetTabCount`;
export const SKILL_PAGED_SKILLS = `${BASE_URL}/Skill/GetPagedSkills`;
export const SKILL_ADD = `${BASE_URL}/Skill/AddNewSkill`;
export const SKILL_DETAIL = `${BASE_URL}/Skill/GetSkillDetail`;
export const SKILL_UPDATE = `${BASE_URL}/Skill/UpdateSkill`;
export const SKILL_DELETE = `${BASE_URL}/Skill/DeleteSkill`;
export const SKILL_RECOVER = `${BASE_URL}/Skill/RecoverSkill`;

/* Product */
export const PRODUCT_FILTER_LIST = `${BASE_URL}/Product/GetFilters`;
export const PRODUCT_CATEGORY_LIST = `${BASE_URL}/Product/GetCategories`;
export const PRODUCT_PAGED_PRODUCTS = `${BASE_URL}/Product/GetPagedProducts`;
export const PRODUCT_DETAIL = `${BASE_URL}/Product/GetProductDetail`;
export const PRODUCT_UPDATE = `${BASE_URL}/Product/UpdateProduct`;

/* Layout */
export const LAYOUT_GET_HOME_BANNERS = `${BASE_URL}/Layout/GetHomeBanners`;
export const LAYOUT_PAGED_VIDEOS = `${BASE_URL}/Layout/GetPagedVideos`;
export const LAYOUT_GET_VIDEO_LAYOUTS = `${BASE_URL}/Layout/GetVideoLayouts`;
export const LAYOUT_SAVE_VIDEO_LAYOUT = `${BASE_URL}/Layout/SaveVideoLayout`;
export const LAYOUT_SAVE_HOME_BANNERS = `${BASE_URL}/Layout/SaveHomeBanners`;
export const LAYOUT_GET_VIDEO_BANNERS = `${BASE_URL}/Layout/GetVideoBanners`;
export const LAYOUT_SAVE_VIDEO_BANNERS = `${BASE_URL}/Layout/SaveVideoBanners`;
export const LAYOUT_GET_NEWS_BY_MODULE = `${BASE_URL}/Layout/GetNewsByModule`;
export const LAYOUT_SAVE_NEWS_PUBLISH_RULE = `${BASE_URL}/Layout/SaveNewsPublishRule`;
export const LAYOUT_GET_TRENDS_VIDEOS = `${BASE_URL}/Layout/GetTrendsVideos`;
export const LAYOUT_SAVE_TRENDS_VIDEOS = `${BASE_URL}/Layout/SaveTrendsVideos`;
export const LAYOUT_GET_HOME_NEWS = `${BASE_URL}/Layout/GetHomeNews`;
export const LAYOUT_SAVE_HOME_NEWS = `${BASE_URL}/Layout/SaveHomeNews`;
export const LAYOUT_GET_HOME_LEARNING = `${BASE_URL}/Layout/GetHomeLearning`;
export const LAYOUT_SAVE_HOME_LEARNING = `${BASE_URL}/Layout/SaveHomeLearning`;
export const LAYOUT_GET_HOME_HOT_RECIPE = `${BASE_URL}/Layout/GetHomeHotRecipe`;
export const LAYOUT_SAVE_HOME_HOT_RECIPE = `${BASE_URL}/Layout/SaveHomeHotRecipe`;
export const LAYOUT_SAVE_CF_NEWS_PUBLISH_RULE = `${BASE_URL}/Layout/SaveCFNewsPublishRule`;
export const LAYOUT_SAVE_LEARNING_PUBLISH_RULE = `${BASE_URL}/Layout/SaveLearningPublishRule`;
export const LAYOUT_GET_LEARNING_SKILLS_BY_RULE = `${BASE_URL}/Layout/GetLearningSkillsByRule`;
export const LAYOUT_GET_LEARNING_SKILL_BY_RULE = `${BASE_URL}/Layout/GetLearningRecipeByRule`;
export const LAYOUT_GET_LEARNING_EXPERTS_BY_RULE = `${BASE_URL}/Layout/GetLearningExpertsByRule`;
export const LAYOUT_GET_LEARNING_PRODUCTS_BY_RULE = `${BASE_URL}/Layout/GetLearningProductsByRule`;
export const LAYOUT_SAVE_LEARNING_BANNERS = `${BASE_URL}/Layout/SaveLearningBanners`;
export const LAYOUT_SAVE_LEARNING_SUB_BANNERS = `${BASE_URL}/Layout/SaveLearningSubBanners`;
export const LAYOUT_GET_LEARNING_BANNERS = `${BASE_URL}/Layout/GetLearningBanners`;
export const LAYOUT_GET_LEARNING_SUB_BANNERS = `${BASE_URL}/Layout/GetLearningSubBanners`;

export const LAYOUT_GET_SEARCH_WORD = `${BASE_URL}/Layout/GetSearchPopularKeyWord`;
export const LAYOUT_SAVE_SEARCH_WORD = `${BASE_URL}/Layout/SaveSearchPopularKeyWord`;
export const LAYOUT_GET_SEARCH_PRODUCTS = `${BASE_URL}/Layout/GetSearchTrendingProducts`;
export const LAYOUT_SAVE_SEARCH_PRODUCTS = `${BASE_URL}/Layout/SaveSearchTrendingProducts`;

/* Customer */
export const CUSTOMER_GET_USER_MEMBERSHIP = `${BASE_URL}/Customer/GetRUserMembership`;
export const CUSTOMER_GET_R_USER_EXPERTISE_LEVEL = `${BASE_URL}/Customer/GetRUserExpertiseLevel`;
export const CUSTOMER_GET_PAGED_R_USERS = `${BASE_URL}/Customer/GetPagedRUsers`;
export const CUSTOMER_GET_CUSTOMER_BY_ID = `${BASE_URL}/Customer/GetCustomerById`;
export const CUSTOMER_ADD_NEW_R_USER = `${BASE_URL}/Customer/AddNewRUser`;
export const CUSTOMER_UPDATE_R_USER = `${BASE_URL}/Customer/UpdateRUser`;
export const CUSTOMER_DELETE_R_USER = `${BASE_URL}/Customer/DeleteRUser`;
export const CUSTOMER_UPDATE_R_USER_PWD = `${BASE_URL}/Customer/UpdateRUserPwd`;
export const CUSTOMER_GET_SURVEY = `${BASE_URL}/Customer/GetSurvey`;

/* Engagement */
export const ENGAGEMENT_GET_PAGED_CONNECT_US = `${BASE_URL}/Engagement/GetPagedConnectUS`;
export const ENGAGEMENT_DELETE_CONNECT_US = `${BASE_URL}/Engagement/DeleteConnectUS`;
export const ENGAGEMENT_MARK_CONNECT_US = `${BASE_URL}/Engagement/MarkConnectUS`;
export const ENGAGEMENT_GET_ALL_COMPANY_INFO_LIST = `${BASE_URL}/Engagement/GetAllCompanyInfoList`;
export const ENGAGEMENT_SAVE_COMPANY_INFO = `${BASE_URL}/Engagement/SaveCompanyInfo`;
export const ENGAGEMENT_GET_SOCIAL_LIST = `${BASE_URL}/Engagement/GetSocialList`;
export const ENGAGEMENT_SAVE_SOCIAL_LINKS = `${BASE_URL}/Engagement/SaveSocialLinks`;
export const ENGAGEMENT_GET_FAQ = `${BASE_URL}/Engagement/GetFAQ`;
export const ENGAGEMENT_SAVE_FAQ= `${BASE_URL}/Engagement/SaveFAQ`;
export const ENGAGEMENT_GET_SEARCH_TITLE_LIST= `${BASE_URL}/Engagement/GetSearchTitleList`;
export const ENGAGEMENT_GET_PAGED_COMMENT_LSIT= `${BASE_URL}/Engagement/GetPagedCommentList`;
export const ENGAGEMENT_REPLY_COMMENT= `${BASE_URL}/Engagement/ReplyComment`;
export const ENGAGEMENT_DELETE_COMMENT= `${BASE_URL}/Engagement/DeleteComment`;

/* PopEvent */
export const POP_EVENT_VIDEO_LIST = `${BASE_URL}/PopEvent/GetPopEventVideoList`;
export const POP_EVENT_NEWS_LIST = `${BASE_URL}/PopEvent/GetPopEventNewsList`;
export const POP_EVENT_DESIGN_LIST = `${BASE_URL}/PopEvent/GetPopEventDesignList`;
export const POP_EVENT_SKILL_LIST = `${BASE_URL}/PopEvent/GetPopEventSkillList`;
export const POP_EVENT_PRODUCT_LIST = `${BASE_URL}/PopEvent/GetPopEventProductList`;
export const POP_EVENT_RECIPE_LIST = `${BASE_URL}/PopEvent/GetPopEventRecipeList`;
export const POP_EVENT_EVENT_LIST = `${BASE_URL}/PopEvent/GetPopEventEventList`;
export const POP_EVENT_TAB_COUNT = `${BASE_URL}/PopEvent/GetTabCount`;
export const POP_EVENT_PAGED_EVENTS = `${BASE_URL}/PopEvent/GetPopEvents`;
export const POP_EVENT_ADD = `${BASE_URL}/PopEvent/AddNewPopEvent`;
export const POP_EVENT_DETAIL = `${BASE_URL}/PopEvent/GetPopEventDetail`;
export const POP_EVENT_UPDATE = `${BASE_URL}/PopEvent/UpdatePopEvent`;
export const POP_EVENT_DELETE = `${BASE_URL}/PopEvent/DeletePopEvent`;
export const POP_EVENT_RECOVER = `${BASE_URL}/PopEvent/RecoverPopEvent`;

/* Event */
export const EVENT_PLACE_LIST = `${BASE_URL}/Event/GetPlaces`;
export const EVENT_LEVEL_LIST = `${BASE_URL}/Event/GetLevels`;
export const EVENT_CHANNEL_LIST = `${BASE_URL}/Event/GetChannels`;
export const EVENT_LOCATION_LIST = `${BASE_URL}/Event/GetLocations`;
export const EVENT_TYPE_LIST = `${BASE_URL}/Event/GetTypes`;
export const EVENT_ORGANIZER_GET = `${BASE_URL}/Event/GetOrganizer`;
export const EVENT_ORGANIZER_DELETE = `${BASE_URL}/Event/DeleteOrganizer`;
export const EVENT_ORGANIZER_ADD = `${BASE_URL}/Event/AddNewOrganizer`;
export const EVENT_TAB_COUNT = `${BASE_URL}/Event/GetTabCount`;
export const EVENT_PAGED_EVENTS = `${BASE_URL}/Event/GetBEPagedEvents`;
export const EVENT_GET = `${BASE_URL}/Event/GetEvent`;
export const EVENT_DELETE = `${BASE_URL}/Event/DeleteEvent`;
export const EVENT_ADD = `${BASE_URL}/Event/AddNewEvent`;
export const EVENT_UPDATE = `${BASE_URL}/Event/UpdateEvent`;
export const EVENT_USER_GET = `${BASE_URL}/Event/GetBEPagedBookingUser`;
export const EVENT_USER_ADD = `${BASE_URL}/Event/AddNewBookingUser`;
export const EVENT_USER_UPDATE = `${BASE_URL}/Event/UpdateBookingUser`;
export const EVENT_USER_DELETE = `${BASE_URL}/Event/DeleteBookingUser`;