<script lang="ts" setup></script>
<template>
  <div class="page-footer">
    <div class="copyright">
      Copyright
      <CopyrightOutlined class="icon-copyright" /> Rich's Product Corporation
    </div>
  </div>
</template>

<style scoped lang="less">
  .page-footer {
    text-align: center;
    @apply text-gray-400;
    .copyright {
      margin-top: 2px;
      .icon-copyright {
        margin: 0;
      }
    }
  }
</style>
