<template>
  <ThemeProvider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#d31145' } }">
    <div class="login-box rounded-sm">
      <div class="flex">
        <img class="w-[200px] mx-auto" src="@/assets/image/login-icon.png" />
      </div>
      <a-form :model="form" :wrapperCol="{ span: 24 }" @finish="clickLogin"
        class="login-form w-[400px] p-lg xl:w-[440px] xl:p-xl h-fit text-text mt-5">
        <a-form-item :required="true" name="username">
          <a-input v-model:value="form.username" autocomplete="new-username" placeholder="Admin"
            class="login-input h-[40px]" style="background-color: #F9F9F9;">
            <template #prefix>
              <span class="w-[8px] h-10 bg-red-1" style="margin-left: -13px;"></span>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item :required="true" name="password">
          <a-input-password v-model:value="form.password" autocomplete="new-password" placeholder="Password"
            class="login-input h-[40px]" style="background-color: #F9F9F9;">
            <template #prefix>
              <span class="w-[8px] h-10 bg-red-1" style="margin-left: -13px;"></span>
            </template>
          </a-input-password>
        </a-form-item>
        <div class="flex">
          <a-checkbox v-model:checked="checked" class="mx-auto text-red-1">Keep me sign in</a-checkbox>
        </div>
        <a-button shape="round" htmlType="submit" class="h-[40px] w-full mt-20" type="primary" :loading="loading"> Login
        </a-button>
      </a-form>
    </div>
  </ThemeProvider>
</template>

<script lang="ts" setup>
  import {
    reactive,
    ref
  } from 'vue';
  import {
    useAccountStore,
  } from '@/store';
  import {
      getRoutePermissionAPI,
  } from '@/api/user'
  import { useAuthStore } from '@/plugins';
  import {
    ThemeProvider
  } from 'stepin';
  import {
    message
  } from 'ant-design-vue';

  export interface LoginFormProps {
    username: string;
    password: string;
  }
  const loading = ref(false);
  const checked = ref(false);

  const form = reactive({
    username: undefined,
    password: undefined,
  });

  const emit = defineEmits < {
    (e: 'success', fields: LoginFormProps): void;
    (e: 'failure', reason: string, fields: LoginFormProps): void;
  } > ();

  const accountStore = useAccountStore();
  const { setAuthorities } = useAuthStore();

  function clickLogin(params: LoginFormProps) {
    loading.value = true;
    accountStore
      .login(params.username, params.password)
      .then((res) => {
        emit('success', params);
        setAuthorities(['edit','menu:edit','system:user:edit',]);
      })
      .catch((e) => {
        emit('failure', e.message, params);
      })
      .finally(() => (loading.value = false));
  }
  // function clickLogin(params: LoginFormProps) {
  //   loading.value = true;
  //   const loginParams = {
  //     account: params.username,
  //     password: params.password,
  //   }
  //   loginAPI(loginParams)
  //     .then(res => {
  //       loading.value = false;
  //       if (res.code == 0) {
  //         let account = {};
  //         account.username = res.data.name;
  //         account.avatar = res.data.avatar;
  //         localStorage.setItem("account", JSON.stringify(account));
  //         http.setAuthorization(res.data.token, new Date(res.data.expireAt));
  //         emit('success', params);
  //         // menuStore.getMenuList();
  //         // getRoutePermission();
  //         setAuthorities(['edit','menu:edit','system:user:edit',]);
  //         // message.success(res.message);
          
  //       } else {
  //         message.error(res.message);
  //       }
  //     }, (error) => {
  //       loading.value = false;
  //       message.error(error.message);
  //     });
  // }
  
  function getRoutePermission() {
    getRoutePermissionAPI()
      .then(res => {
        if (res.code == 0) {
          console.log(res.data)
          setAuthorities(res.data);
        } else {
          message.error(res.message);
        }
      }, (error) => {
        message.error(error.message);
      });
  }
</script>

<style lang="less" scoped>
  .login-form {
    ::v-deep .ant-input {
      background-color: #F9F9F9 !important;
      color: #000;
    }

    //谷歌浏览器的自动填充会覆盖掉写好的样式，用延时动画
    ::v-deep input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 99999s;
      -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
    }
  }
</style>
